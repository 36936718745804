<template>
	<div class="animated fadeIn">
		<div
			v-if="!$route.params.distributorId"
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr v-if="!$route.params.distributorId">
		<data-filter
			v-if="!$route.params.distributorId"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-if="admin.includes($user.details().type)"
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor,customer,affiliate'}"
							class="mb-3"
							q="label"
							return-key="id" />
						<search
							v-else
							v-model="filters.fromId"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor,customer,affiliate'}"
							:init-value="filters.fromId"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div
					v-if="false && dateRange === dateRanges.custom"
					class="row mb-4">
					<div class="col">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div class="col">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="border-top-0">
							{{ translate('username') }}
						</th>
						<th
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="border-top-0">
							{{ translate('country') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('from_id')">
							{{ translate('from_user_id') }}
							<sort field="from_id" />
						</th>
						<th
							class="border-top-0">
							{{ translate('from_username') }}
						</th>
						<th class="border-top-0">
							{{ translate('description') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('order_id')">
							{{ translate('order_id') }}
							<sort field="order_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('amount_left')">
							{{ translate('bv_left') }}
							<sort field="amount_left" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('amount_right')">
							{{ translate('bv_right') }}
							<sort field="amount_right" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('created_at')">
							{{ translate('date_time') }}
							<sort field="created_at" />
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="align-middle">
							<span
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							v-if="admin.includes($user.details().type) && !$route.params.distributorId"
							class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.user_country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.user_country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.user_country.toLowerCase())">
						</td>
						<td class="align-middle">
							<template v-if="item.attributes.is_spillover">
								{{ translate('NA') }}
							</template>
							<span
								v-else-if="admin.includes($user.details().type) && item.attributes.from_user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.from_id.toString() })">
								{{ item.attributes.from_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.from_id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.is_spillover ? `(${translate('spillover').toUpperCase()})` : item.attributes.from_username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.description }}
						</td>
						<td class="align-middle">
							{{ item.attributes.order_id }}
						</td>
						<td class="align-middle">
							{{ item.attributes.amount_left }}
						</td>
						<td class="align-middle">
							{{ item.attributes.amount_right }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.created_at.date).format(dateFormat) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle text-center">
							<b-button
								v-if="showRestoreButton(item.attributes.code_name_description, item.attributes.related_id)"
								v-b-tooltip.hover
								:title="translate('restore_bv')"
								variant="primary"
								class="bg-primary-alt"
								@click="restoreBv(item.id)">
								<i
									class="fa fa-undo"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Report as BR, Grids, BinaryVolume, Countries, Users,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { YMDHMS_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Bv from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import { admin, distributor } from '@/settings/Roles';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'Bv',
	messages: [BR, Grids, BinaryVolume, Grids, Countries, Users],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			bv: new Bv(),
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			dateRanges,
			endpoint,
			calendarConfig: V_CALENDAR_CONFIG,
			active: 'active',
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			try {
				return this.bv.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.bv.data.errors.errors;
		},
		pagination() {
			return this.bv.data.pagination;
		},
		data() {
			try {
				const { data } = this.bv.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			try {
				return !!this.data.length;
			} catch (error) {
				return false;
			}
		},
		userStatus() {
			try {
				const { status } = this.bv.data.response.data.meta;
				return status;
			} catch (error) {
				return '';
			}
		},
	},
	mounted() {
		let userId = this.$user.details().id;
		if (this.$route.params.distributorId) {
			userId = this.$route.params.distributorId;
		}
		this.bv.getBv(userId);
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			let userId = this.$user.details().id;
			if (this.$route.params.distributorId) {
				userId = this.$route.params.distributorId;
			}
			this.bv.getBv(userId, options);
		},
		showRestoreButton(codeName, relatedId) {
			return this.$can('bv', 'restore')
				&& codeName === 'bv_flush'
				&& relatedId === null
				&& this.userStatus === this.active;
		},
		restoreBv(bvId) {
			const trans = {
				title: this.translate('restore_bv'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};

				this.bv.restoreBv(bvId, payload).then(() => {
					this.alert.toast('success', this.translate('bv_restored'));
					let userId = this.$user.details().id;
					if (this.$route.params.distributorId) {
						userId = this.$route.params.distributorId;
					}
					this.bv.getBv(userId);
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
	},
};
</script>
